import {Component} from 'react';
import {evite, assert, set} from 'evite';
import {StatusAnimation} from '~/create/components/StatusAnimation';

// @example
// const MyComponent = (props) => {
//   return <AsyncComponent
//     exportName="MyComponent"
//     resolve={() => {
//       return import('~/some/path/MyComponent');
//     }}
//     onRef={props.onRef}
//     LoadingComponent={LoadingComponentClass}
//     ErrorComponent={ErrorComponentClass}
//     loaderStyle={{width: '100%', height: '100%'}}
//     loaderChildren={null}
//     {...props}
//   />;
// };*/

export class AsyncComponent extends Component {
  ComponentClass = null;

  minLoadingScreenDelayPromise = null;

  enableLoadingScreen = false;

  status = '';

  mounted = false;

  UNSAFE_componentWillMount() {
    if (!this.minLoadingScreenDelayPromise) {
      // prevent showing a loading screen flash in the first moments
      this.minLoadingScreenDelayPromise = evite.sleep(600).then(() => {
        this.enableLoadingScreen = true;
        if (!this.ComponentClass && this.mounted) {
          this.forceUpdate();
        }
      });
    }

    if (!this.ComponentClass) {
      this.status = 'loading';

      this.props
        .resolve()
        .then((exports) => {
          this.status = 'complete';
          const moduleName = this.props.exportName || 'default';
          this.ComponentClass = assert(
            exports[moduleName],
            'Missing module: --exportName="%s"--exports=%O',
            moduleName,
            exports
          );

          if (this.mounted) {
            this.forceUpdate();
          }
        })
        .catch((error) => {
          evite.error(
            `AsyncComponent Error: --component-name=${this.props.exportName} --component=%O --error=${error.message}`,
            this.props.exportName,
            this.ComponentClass,
            (error && error.message) || error
          );
          evite.logError(error);

          this.status = 'error';

          if (this.mounted) {
            this.forceUpdate();
          }
        });
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {ComponentClass, props, status, enableLoadingScreen} = this;
    const {
      exportName,
      resolve,
      LoadingComponent,
      ErrorComponent,
      loaderStyle,
      loaderChildren,
      children,
      ...componentProps
    } = props;

    const childModifiers = evite.styleModifiers('async-component', {
      [exportName]: true,
    });

    if (status !== 'complete' && !enableLoadingScreen) {
      return null;
    }
    if (status === 'loading' && LoadingComponent) {
      return (
        <LoadingComponent style={loaderStyle} className={childModifiers} {...componentProps}>
          {loaderChildren}
        </LoadingComponent>
      );
    }
    if (status === 'error' && ErrorComponent) {
      return (
        <ErrorComponent style={loaderStyle} className={childModifiers} {...componentProps}>
          {loaderChildren}
        </ErrorComponent>
      );
    }
    if (!ComponentClass) {
      return (
        <StatusAnimation
          style={loaderStyle}
          className={childModifiers}
          status={this.status}
          styleModifiers={{[exportName]: true}}
        >
          {loaderChildren}
        </StatusAnimation>
      );
    }

    return <ComponentClass {...componentProps}>{children}</ComponentClass>;
  }
}

evite.exports.AsyncComponent = AsyncComponent;
