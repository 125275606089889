import React, {Component} from 'react';
import {evite} from 'evite';

export class StatusAnimation extends Component {
  render() {
    const {status, styleModifiers, style, children} = this.props;
    const childModifiers = evite.styleModifiers('status-animation', {
      [status]: true,
      ...styleModifiers,
    });

    return (
      <div className={`status-animation__status-animation ${childModifiers} `} style={style}>
        <i className={`status-animation__icon ${childModifiers}`} />
        {children}
      </div>
    );
  }
}
